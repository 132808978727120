import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { cva } from 'class-variance-authority';
import { Button } from '../Button';
import { useDialogContext } from './DialogContext';

export interface DialogCloseProps {
  onClose: () => void;
  children: React.ReactNode;
}

const closeStyle = cva(
  'bg-coral-50 top-3 absolute animate-btn flex h-[26px] min-w-[26px] p-0 items-center justify-center rounded-bl-none rounded-br-lg rounded-tl-lg rounded-tr-none',
  {
    variants: {
      slideFrom: {
        right: 'left-3',
        left: 'right-3',
      },
    },
  }
);

export const DialogClose: React.FC<DialogCloseProps> = ({ onClose, children }) => {
  const { slideFrom } = useDialogContext();

  return (
    <RadixDialog.Close asChild onClick={onClose}>
      <Button variant={'borderless'} className={closeStyle({ slideFrom })}>
        {children}
      </Button>
    </RadixDialog.Close>
  );
};
