import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { TextBox } from 'ui-v2';

export const Route = createFileRoute('/definitions')({
  component: Definitions,
});

function Definitions() {
  return (
    <div className="space-y-4">
      <TextBox>
        <TextBox.Heading>Definitions</TextBox.Heading>
        <TextBox.Paragraph>Content to come later</TextBox.Paragraph>
      </TextBox>
    </div>
  );
}
