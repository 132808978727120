import React, { FC, useEffect, useRef, useState } from 'react';
import { cn } from 'utils';
import { PieChartData } from './PieChart';
import { Tooltip } from '../Tooltip';

interface PieTooltipProps {
  active?: boolean;
  payload?: { payload: PieChartData }[];
}

export const PieTooltip: FC<PieTooltipProps> = ({ active, payload }) => {
  const [position, setPosition] = useState<'left' | 'right' | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const hasPayload = payload?.length;
  const isShown = active && position && hasPayload;

  useEffect(() => {
    if (!active) {
      setPosition(null);
      return;
    }

    const rect = ref?.current?.getBoundingClientRect();
    setPosition((Number(rect?.x) + 155 / 2) / window.innerWidth > 0.5 ? 'left' : 'right');
  }, [active]);

  if (!hasPayload) return null;

  const { label, tooltipLabel, color, displayValue } = payload[0].payload;

  return (
    <div ref={ref}>
      {isShown && (
        <Tooltip variant="static">
          <Tooltip.Content
            className={cn('absolute top-[155px] min-w-72', {
              'left-[0px]': position === 'right',
              'right-[-155px]': position === 'left',
            })}
          >
            <Tooltip.ChartValue label={tooltipLabel ?? label} value={displayValue} color={color} />
          </Tooltip.Content>
        </Tooltip>
      )}
    </div>
  );
};
