import React from 'react';
import * as Popover from '@radix-ui/react-popover';

import { useTooltipContext } from './TooltipContext';

export function TooltipArrow() {
  const { variant } = useTooltipContext();
  if (variant === 'static') return null;
  return <Popover.Arrow width={14} height={9} className="fill-white shadow-lg" />;
}
