import React, { type ComponentProps } from 'react';
import { cn } from 'utils';

export function ChartCardHeader({ className, children, ...props }: ComponentProps<'header'>) {
  return (
    <header className={cn('shrink-0', className)} {...props}>
      {children}
    </header>
  );
}
