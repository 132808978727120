import { gql } from '@/generated';

import { useFilterConditions } from '@/contexts';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { ValidateFiltersQuery, ValidateFiltersQueryVariables } from '@/generated/graphql';

const VALIDATE_FILTERS = gql(/* GraphQL */ `
  query ValidateFilters($filter: [FilterCond]) {
    forestryIndicators(filter: $filter) {
      numberOfOrgs
    }
  }
`);

export function useIsValidFilters(
  options: QueryHookOptions<ValidateFiltersQuery, ValidateFiltersQueryVariables> = {}
) {
  const { benchmark } = useFilterConditions();
  const query = useQuery(VALIDATE_FILTERS, { variables: { filter: benchmark }, ...options });
  const numberOfOrgs = query.data?.forestryIndicators?.numberOfOrgs;
  return {
    ...query,
    isValid: numberOfOrgs && numberOfOrgs > 2,
  };
}
