import React from 'react';
import { BorderBox, SectionTitle } from 'ui-v2';

import { ReactComponent as InvalidFiltersGraphic } from '../svg/InvalidFilters.svg';

export function InvalidFilters() {
  return (
    <BorderBox className="space-y-8 py-8 text-center text-gray-500 sm:space-y-12 sm:py-12">
      <InvalidFiltersGraphic className="mx-auto block max-w-full" />
      <div className="space-y-2 sm:space-y-4">
        <SectionTitle>Seems like you&apos;ve applied too many filters&hellip;</SectionTitle>
        <p>Try removing some filters to see more results</p>
      </div>
    </BorderBox>
  );
}
