import React, { PropsWithChildren } from 'react';
import { Action } from './Action';
import { Filters } from './Filters';

interface DialogComposition {
  Action: typeof Action;
  Filters: typeof Filters;
}

const FilterBar: React.FC<PropsWithChildren> & DialogComposition = ({ children }) => (
  <div className="flex items-center gap-2.5">{children}</div>
);

FilterBar.Action = Action;
FilterBar.Filters = Filters;
export { FilterBar };
