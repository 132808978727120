import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'utils';
import { BorderBox } from '../BorderBox';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import { Link } from './Link';

interface TextBoxComposition {
  Heading: typeof Heading;
  Paragraph: typeof Paragraph;
  Link: typeof Link;
}

interface TextBoxOwnProps {
  className?: string;
}

export const TextBox: FC<PropsWithChildren & TextBoxOwnProps> & TextBoxComposition = ({
  children,
  className,
}) => <BorderBox className={cn('space-y-3 p-6 sm:p-6', className)}>{children}</BorderBox>;

TextBox.Heading = Heading;
TextBox.Paragraph = Paragraph;
TextBox.Link = Link;
