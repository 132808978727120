import React, { forwardRef, PropsWithChildren } from 'react';
import { cn } from 'utils';

interface TooltipContainerProps {
  className?: string;
}

export const TooltipContainer = forwardRef<HTMLDivElement, PropsWithChildren & TooltipContainerProps>(
  ({ children, className }, ref) => (
    <div
      className={cn('z-20 max-w-max rounded bg-white p-4 shadow-lg outline-none', className)}
      ref={ref}
    >
      {children}
    </div>
  )
);
