import shuffle from 'lodash/shuffle';

// Define the color groups
const defaultColorGroups: string[][] = [
  ['#FDAE6B', '#FDAF8B', '#FED684'], // Orange
  ['#9E9AC8', '#D4B9DA', '#BBB8D9'], // Purple
  ['#8ED29F', '#94D4BF', '#A1D99B', '#C7E9B4'], // Green
  ['#9ECAE1', '#7ACCD6', '#B1E4DF', '#68BFF6', '#A3D9D9', '#C5D9D5', '#649CCB', '#D0E2F2'], // Blue
  ['#C4C4B7', '#CBD3C9', '#ABB8A8', '#B7C1AD', '#CDC5B0', '#D2BF9A', '#89896E'], // Brown
];

// Main function of the color generation
export const generateChartColors = (
  desiredColors: number,
  colors: string[][] = defaultColorGroups
): string[] => {
  if (!desiredColors) return [];

  const result: string[] = [];
  let groups = colors.map((group) => shuffle([...group])); // Shuffle each group with Lodash
  const maxLength = Math.max(...groups.map((group) => group.length)); // Find the maximum group size

  // Main cycle by number of colors
  for (let i = 0; i < maxLength; i++) {
    groups = shuffle(groups); // Shuffle order of the groups before each step

    // Iterate by groups and add colors
    for (const group of groups) {
      const color = group[i % group.length]; // Take color by index
      if (color && !result.includes(color)) {
        result.push(color);
      }
      if (result.length >= desiredColors) return result; // If enough colors are collected, return the result
    }
  }

  // If not enough colors, call the function recursively for good left
  const additionalColors = generateChartColors(desiredColors - result.length, colors);
  return [...result, ...additionalColors];
};
