import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from 'i18n-iso-countries';

import en from './locales/en.json';

import('i18n-iso-countries/langs/en.json').then((module) => registerLocale(module.default));

i18n.use(initReactI18next).init({
  resources: { en },
  lng: 'en',
});
