import React, { FC, PropsWithChildren } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from 'utils/cn';

interface BorderBoxProps {
  color?: 'white' | 'gray';
  borderStyle?: 'solid' | 'dashed';
  className?: string;
}

const borderBoxStyle = cva('p-4 sm:py-6 sm:px-9 border-gray-300', {
  variants: {
    color: {
      white: 'bg-white',
      gray: 'bg-gray-50',
    },
    borderStyle: {
      solid: 'border-0.5 rounded-xl',
      dashed: 'border-dashed border rounded-lg',
    },
  },
});

export const BorderBox: FC<PropsWithChildren & BorderBoxProps> = ({
  color = 'white',
  borderStyle = 'solid',
  className,
  children,
}) => (
  <section className={cn(borderBoxStyle({ color, borderStyle }), className)}>{children}</section>
);
