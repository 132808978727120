import React, { ReactNode } from 'react';
import { cn } from 'utils/cn';
import { useNavMenuContext } from './NavMenuContext';

export interface NavLinksProps {
  children: ReactNode;
}

export const Links: React.FC<NavLinksProps> = ({ children }) => {
  const { layout } = useNavMenuContext();

  const layoutStyles = cn('flex', {
    'gap-y-4 flex-col items-end': layout === 'mobile',
    'flex-row items-center gap-x-12': layout === 'desktop',
  });

  return <ul className={layoutStyles}>{children}</ul>;
};
