import React from 'react';
import * as Popover from '@radix-ui/react-popover';

import { TooltipContainer } from './TooltipContainer';
import { useTooltipContext } from './TooltipContext';

export function TooltipContent({
  children,
  className,
  ...props
}: React.PropsWithChildren & React.ComponentPropsWithoutRef<typeof Popover.Content>) {
  const { variant, isVisible } = useTooltipContext();

  if (!isVisible) return null;

  if (variant === 'static') {
    return <TooltipContainer className={className}>{children}</TooltipContainer>;
  }

  return (
    <Popover.Portal>
      <Popover.Content {...props} asChild>
        <TooltipContainer className={className}>{children}</TooltipContainer>
      </Popover.Content>
    </Popover.Portal>
  );
}
