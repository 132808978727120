import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { TextBox } from 'ui-v2';

export const Route = createFileRoute('/acknowledgements')({
  component: Acknowledgements,
});

function Acknowledgements() {
  return (
    <div className="space-y-4">
      <TextBox>
        <TextBox.Heading>Acknowledgements</TextBox.Heading>
        <TextBox.Paragraph>Content to come later</TextBox.Paragraph>
      </TextBox>
    </div>
  );
}
