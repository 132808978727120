import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

export interface DialogContextProps {
  slideFrom: 'right' | 'left';
}

export const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export function useDialogContext() {
  const ctx = useContext(DialogContext);

  if (!ctx) throw new Error('Dialog.* components must be rendered within a Dialog');

  return ctx;
}

export const DialogContextProvider: FC<PropsWithChildren & DialogContextProps> = ({
  children,
  slideFrom,
}) => {
  const contextValue = useMemo(() => ({ slideFrom }), [slideFrom]);

  return <DialogContext.Provider value={contextValue}>{children}</DialogContext.Provider>;
};
