import React, { FC } from 'react';
import { LabelText } from '../LabelText';
import { Swatch } from '../Swatch';

export const ChartLegendItem: FC<{ item: { color: string; label: string } }> = ({ item }) => (
  <li className="flex items-center gap-2">
    {item.color && <Swatch color={item.color} />}
    <LabelText>{item.label}</LabelText>
  </li>
);
