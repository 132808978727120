import React, { ComponentPropsWithoutRef } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import { BorderBox } from '../BorderBox';
import { cn } from 'utils';

type PopoverContentProps = ComponentPropsWithoutRef<typeof BorderBox> &
  Omit<ComponentPropsWithoutRef<typeof RadixPopover.Content>, 'children' | 'className' | 'asChild'>;

export const PopoverContent: React.FC<PopoverContentProps> = ({
  children,
  className,
  ...props
}) => (
  <RadixPopover.Portal>
    <RadixPopover.Content sideOffset={1} align="start" className="z-50 max-w-[95vw]" {...props}>
      <BorderBox className={cn('animate-fadeIn overflow-auto shadow-lg', className)}>
        {children}
      </BorderBox>
    </RadixPopover.Content>
  </RadixPopover.Portal>
);
