import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'utils';

interface ParagraphProps {
  className?: string;
}

export const Paragraph: FC<ParagraphProps & PropsWithChildren> = ({ className, children }) => (
  <p className={cn('text-base leading-[1.375rem] text-gray-900', className)}>{children}</p>
);
