import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './tailwind.config';

const resolvedConfig = resolveConfig(tailwindConfig);

const { colors } = resolvedConfig.theme;

export { colors };

export const getSdgColors = () => {
  const sdgColors = Object.entries(colors.sdg).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      acc[`sdg-${key}`] = value;
    }
    return acc;
  }, {} as Record<string, string>);

  return sdgColors;
};
