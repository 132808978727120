import { createContext, useContext } from 'react';

import { HorizontalBarProps } from '../HorizontalBar';

export type HorizontalBarsContextValue = Pick<
  HorizontalBarProps,
  'max' | 'min' | 'showInlineDisplayValue'
>;

export const HorizontalBarsContext = createContext<HorizontalBarsContextValue | null>(null);

export const useHorizontalBarsContext = () => {
  const ctx = useContext(HorizontalBarsContext);
  if (!ctx) {
    throw new Error(
      'HorizontalBars.* components must be rendered within a HorizontalBars component'
    );
  }
  return ctx;
};
