import React from 'react';
import { Link, Outlet, createRootRoute } from '@tanstack/react-router';
import { Header, Page } from '../components';
import { BorderBox, Button, SectionTitle } from 'ui-v2';

export const Route = createRootRoute({
  component: () => (
    <div className="flex min-h-screen flex-col">
      <Header />
      <Page>
        <Outlet />
      </Page>
    </div>
  ),
  notFoundComponent: () => (
    <BorderBox className="flex grow flex-col items-center justify-center gap-4 py-12 sm:py-12">
      <SectionTitle>Page does not exist.</SectionTitle>
      <Button as={Link} to="/" variant="outline">
        Back to index
      </Button>
    </BorderBox>
  ),
});
