import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { cn } from 'utils/cn';

const style = {
  button: cva(
    cn(
      // Base
      'flex gap-2 cursor-pointer items-center justify-center rounded-lg py-1.5 min-w-[7.5rem]',
      // Animation
      'transition-transform duration-200 ease-in-out transform active:scale-95'
    ),
    {
      variants: {
        color: {
          midnight: 'text-midnight',
          barbie: 'text-barbie',
        },
        variant: {
          solid: 'text-white border',
          outline: 'bg-transparent border',
          borderless: 'bg-transparent border-none',
        },
        size: {
          default: 'px-6 text-sm h-8',
          small: 'px-4 text-[0.8rem] h-7 min-w-[6.5rem]',
        },
        iconPosition: {
          left: 'flex-row',
          right: 'flex-row-reverse',
        },
      },
      compoundVariants: [
        {
          color: 'midnight',
          variant: 'solid',
          class: 'bg-midnight border-midnight',
        },
        {
          color: 'midnight',
          variant: 'outline',
          class: 'text-midnight border-midnight',
        },
        {
          color: 'barbie',
          variant: 'outline',
          class: 'text-barbie border-barbie',
        },
      ],
      defaultVariants: {
        color: 'midnight',
        variant: 'solid',
        size: 'default',
      },
    }
  ),
};

type ButtonOwnProps = {
  onClick?: () => void;
  icon?: React.ReactNode;
  children?: React.ReactNode;
} & VariantProps<typeof style.button>;

type ButtonProps<C extends React.ElementType> = PolymorphicComponentProp<C, ButtonOwnProps>;

const defaultElement = 'button';

export const Button = <C extends React.ElementType = typeof defaultElement>({
  as,
  onClick,
  color = 'midnight',
  variant = 'solid',
  size = 'default',
  icon,
  iconPosition = 'left',
  children,
  className,
  ...props
}: ButtonProps<C>) => {
  const Component = as || defaultElement;

  return (
    <Component
      className={cn(style.button({ color, variant, size, iconPosition }), className)}
      onClick={onClick}
      {...props}
    >
      {icon}
      {children}
    </Component>
  );
};
