import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

interface HelperTextProps {
  className?: string;
}

export const HelperText: FC<PropsWithChildren<HelperTextProps>> = ({ children, className }) => (
  <p className={cn('text-xs uppercase text-gray-500', className)}>{children}</p>
);
