import * as Popover from '@radix-ui/react-popover';
import React from 'react';

import { TooltipArrow } from './TooltipArrow';
import { TooltipChartValue } from './TooltipChartValue';
import { TooltipContent } from './TooltipContent';
import { TooltipContext } from './TooltipContext';
import { TooltipTrigger } from './TooltipTrigger';

type TooltipProps = React.PropsWithChildren<{
  variant: 'static' | 'tooltip' | 'popover';
  defaultOpen?: boolean;
}>;

type ComposedTooltip = React.FC<TooltipProps> & {
  Arrow: typeof TooltipArrow;
  ChartValue: typeof TooltipChartValue;
  Content: typeof TooltipContent;
  Trigger: typeof TooltipTrigger;
};

export const Tooltip: ComposedTooltip = ({ variant, defaultOpen, children }) => {
  const [isVisible, setIsVisible] = React.useState(variant === 'static' || !!defaultOpen);

  return (
    <TooltipContext.Provider
      value={{
        variant,
        isVisible,
        setIsVisible,
      }}
    >
      {variant === 'static' ? (
        children
      ) : (
        <Popover.Root open={isVisible} onOpenChange={setIsVisible}>
          {children}
        </Popover.Root>
      )}
    </TooltipContext.Provider>
  );
};

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
Tooltip.Arrow = TooltipArrow;
Tooltip.ChartValue = TooltipChartValue;
