import { ElementType } from 'react';
import { cn } from 'utils/cn';

interface SectionTitleOwnProps {
  className?: string;
}

type SectionTitleProps<C extends ElementType> = PolymorphicComponentProp<C, SectionTitleOwnProps>;

const defaultElement = 'h2';

export const SectionTitle = <C extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
  ...props
}: SectionTitleProps<C>) => {
  const Tag = as || defaultElement;

  return (
    <Tag
      className={cn(
        'text-midnight text-wrap text-lg font-light leading-[1.375rem] sm:text-[1.375rem] sm:leading-7',
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};
