import * as Popover from '@radix-ui/react-popover';
import React from 'react';

import { useTooltipContext } from './TooltipContext';

export function TooltipTrigger({ children }: React.PropsWithChildren) {
  const { variant, setIsVisible } = useTooltipContext();

  if (variant === 'static') return null;
  return (
    <Popover.Trigger
      {...(variant === 'tooltip' && {
        onMouseEnter: () => setIsVisible(true),
        onMouseLeave: () => setIsVisible(false),
      })}
      asChild
    >
      {children}
    </Popover.Trigger>
  );
}
