import React, { type ComponentProps, type FC, type PropsWithChildren } from 'react';
import { Checkbox } from '../Checkbox';
import { useFilterGroupContext } from './FilterGroupContext';

type FilterProps = PropsWithChildren<{
  value: string;
  disabled?: ComponentProps<typeof Checkbox>['disabled'];
}>;

export const Filter: FC<FilterProps> = ({ children, disabled, value }) => {
  const { filters, toggleFilter } = useFilterGroupContext();

  return (
    <li className={'flex items-center gap-1.5'}>
      <Checkbox
        checked={filters.includes(value)}
        disabled={disabled}
        onCheckedChange={() => toggleFilter(value)}
      >
        {children}
      </Checkbox>
    </li>
  );
};
