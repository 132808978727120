import { Filter } from './Filter';

interface IFilter {
  label: string | number;
  value: string;
}

interface FiltersProps {
  activeFilters: IFilter[];
  onRemove: (filterValue: string) => void;
}

export const Filters: React.FC<FiltersProps> = ({ activeFilters, onRemove }) => (
  <>
    <div className="hidden flex-wrap items-center gap-2.5 sm:flex">
      {activeFilters.map((filter) => (
        <Filter key={filter.value} label={filter.label} onRemove={() => onRemove(filter.value)} />
      ))}
    </div>

    <div className="flex items-center gap-1 sm:hidden">
      {activeFilters.length > 0 && (
        <Filter label={activeFilters[0].label} onRemove={() => onRemove(activeFilters[0].value)} />
      )}

      {activeFilters.length > 1 && (
        <span className="text-barbie text-nowrap text-xs">+{activeFilters.length - 1}</span>
      )}
    </div>
  </>
);
