import { makeVar, useReactiveVar } from '@apollo/client';

import { useViewer } from '@/hooks/useViewer';
import {
  FilterField,
  FilterOp,
  HealthcareIndicators,
  PercentOfTotalItem,
  type FilterCond,
} from '@/generated/graphql';
import { useMemo } from 'react';

type FilterDefinition = {
  value: string;
  condition?: FilterCond;
  isDisabled?: (data?: PercentOfTotalItem[]) => boolean;
};

type FilterGroup = {
  localePrefix: string;
  field: FilterField;
  indicatorName: keyof HealthcareIndicators;
  filters: FilterDefinition[];
};

export const FILTERS: FilterGroup[] = [
  {
    field: FilterField.AssetClass,
    localePrefix: 'assetClass',
    indicatorName: 'assetClass',
    filters: [
      { value: 'Deposits & cash equivalents' },
      { value: 'Private debt' },
      { value: 'Public debt' },
      { value: 'Private equity' },
      { value: 'Public equity' },
      { value: 'Real assets' },
      { value: 'Other (please specify)' },
    ],
  },
  {
    field: FilterField.YearsSinceInvestment,
    localePrefix: 'yearsSinceInvestment',
    indicatorName: 'assetClass',
    // indicatorName: 'yearsSinceInvestment',
    filters: [
      {
        value: 'sm',
        condition: {
          group: 'yearsSinceInvestment',
          op: FilterOp.In,
          field: FilterField.YearsSinceInvestment,
          value: [1, 2, 3],
        },
        isDisabled: (data) =>
          !data?.some((x) => Number(x.label) >= 1 && Number(x.label) <= 3 && x.count > 5),
      },
      {
        value: 'md',
        condition: {
          group: 'yearsSinceInvestment',
          op: FilterOp.In,
          field: FilterField.YearsSinceInvestment,
          value: [4, 5, 6, 7],
        },
        isDisabled: (data) =>
          !data?.some((x) => Number(x.label) >= 4 && Number(x.label) <= 6 && x.count > 5),
      },
      {
        value: 'lg',
        condition: {
          group: 'yearsSinceInvestment',
          op: FilterOp.Gte,
          field: FilterField.YearsSinceInvestment,
          value: 8,
        },
        isDisabled: (data) => !data?.some((x) => Number(x.label) >= 8 && x.count > 5),
      },
    ],
  },
  {
    field: FilterField.BusinessStage,
    localePrefix: 'stageOfBusiness',
    indicatorName: 'stageOfBusiness',
    filters: [
      { value: 'Seed/startup stage' },
      { value: 'Venture stage' },
      { value: 'Growth stage' },
      { value: 'Mature, private companies' },
      { value: 'Mature, publicly traded companies' },
      { value: 'Not applicable' },
    ],
  },
  {
    field: FilterField.IncomeGroup,
    localePrefix: 'incomeGroup',
    indicatorName: 'assetClass',
    // indicatorName: 'incomeGroup',
    filters: [
      { value: 'Low income' },
      { value: 'Lower middle income' },
      { value: 'Upper middle income' },
      { value: 'High income' },
    ],
  },
  {
    field: FilterField.Region,
    localePrefix: 'region',
    indicatorName: 'primaryCountryByRegion',
    filters: [
      { value: 'Eastern Asia' },
      { value: 'Europe' },
      { value: 'Latin America and Caribbean' },
      { value: 'Northern Africa' },
      { value: 'Northern America' },
      { value: 'Oceania' },
      { value: 'South-eastern Asia' },
      { value: 'Southern Asia' },
      { value: 'Sub-Saharan Africa' },
      { value: 'Western Asia' },
    ],
  },
  {
    field: FilterField.ProductType,
    localePrefix: 'healthcareType',
    indicatorName: 'healthcareType',
    filters: [
      { value: 'Preventative' },
      { value: 'Diagnostic' },
      { value: 'Treatment' },
      { value: 'Pallative' },
    ],
  },
  {
    field: FilterField.StageValueChain,
    localePrefix: 'stageOfValueChain',
    indicatorName: 'assetClass',
    // indicatorName: 'stageOfValueChain',
    filters: [
      { value: 'Research' },
      { value: 'Production' },
      { value: 'Procurement and distribution' },
      { value: 'Product and service provision' },
    ],
  },
  {
    // field: FilterField.Setting,
    field: FilterField.Observation,
    localePrefix: 'settingOfCare',
    indicatorName: 'settingOfCare',
    filters: [
      { value: 'Hospital' },
      { value: 'Specialized care facility' },
      { value: 'Community' },
      { value: 'Home-based' },
      { value: 'Virtual' },
    ],
  },
];

export const filterVisibilityVar = makeVar<boolean>(false);
export const useFilterVisibility = () => useReactiveVar(filterVisibilityVar);

export const activeFiltersVar = makeVar<Record<string, string[]>>({});
export const useActiveFilters = () => useReactiveVar(activeFiltersVar);

export function deriveFilterConditions(filters: Record<string, string[]>): FilterCond[] {
  return Object.entries(filters).reduce<FilterCond[]>((acc, [field, values]) => {
    const filterGroup = FILTERS.find((x) => x.field === field);
    if (!filterGroup || values.length === 0) return acc;

    const filterDefinitions = values
      .map((x) => filterGroup.filters.find((y) => y.value === x))
      .filter((x): x is FilterDefinition => !!x);

    if (filterDefinitions.every((x) => !x.condition)) {
      acc.push({
        op: FilterOp.In,
        field: filterGroup.field,
        value: values,
      });
      return acc;
    }

    acc.push(
      ...filterDefinitions.map((x) => {
        if (x.condition) return x.condition;
        return {
          group: filterGroup.field,
          op: FilterOp.Eq,
          field: filterGroup.field,
          value: x.value,
        };
      })
    );
    return acc;
  }, []);
}

interface FilterConditions {
  benchmark: FilterCond[];
  viewer: FilterCond[] | null;
}

export const useFilterConditions = (): FilterConditions => {
  const activeFilters = useActiveFilters();
  const benchmarkFilterConditions = useMemo(
    () => deriveFilterConditions(activeFilters),
    [activeFilters]
  );

  const { viewer } = useViewer();
  const viewerOrg = viewer?.salesforceAccountId ?? viewer?.orgId;

  return {
    benchmark: benchmarkFilterConditions,
    viewer: useMemo(
      () =>
        viewerOrg
          ? [
              ...benchmarkFilterConditions,
              { field: FilterField.Org, op: FilterOp.Eq, value: viewerOrg },
            ]
          : null,
      [benchmarkFilterConditions, viewerOrg]
    ),
  };
};
