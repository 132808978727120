import { faInvestors } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BigStat, ChartCard, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_InvestorsRepresentedFragment = gql(/* GraphQL */ `
  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {
    numberOfOrgs
  }
`);

type InvestorsRepresentedProps = {
  className?: string;
  indicators: FragmentType<typeof Index_InvestorsRepresentedFragment>;
};

export const InvestorsRepresented: React.FC<InvestorsRepresentedProps> = ({
  indicators,
  className,
}) => {
  const { numberOfOrgs } = useFragment(Index_InvestorsRepresentedFragment, indicators);

  const { title, chartTooltip } = useChartTranslations('investorsRepresented');

  return (
    <ChartCard className={className}>
      <ChartCard.Body className="space-y-1.5">
        <div className="flex items-center justify-between">
          <BigStat className="font-light">{numberOfOrgs}</BigStat>
          <FontAwesomeIcon icon={faInvestors} size="2xl" className="text-barbie size-6 lg:size-8" />
        </div>
        <ChartCard.Title className="text-barbie">
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Body>
    </ChartCard>
  );
};
