import * as RadixDialog from '@radix-ui/react-dialog';
import React from 'react';
import { DialogClose } from './Close';
import { DialogContextProvider } from './DialogContext';
import { DialogOverlay } from './Overlay';
import { DialogWindow } from './Window';

export interface DialogComposition {
  Overlay: typeof DialogOverlay;
  Window: typeof DialogWindow;
  Close: typeof DialogClose;
}

export interface DialogOwnProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  slideFrom?: 'left' | 'right';
}

export const Dialog: React.FC<RadixDialog.DialogProps & DialogOwnProps> & DialogComposition = ({
  isOpen,
  onClose,
  children,
  slideFrom = 'right',
}) => (
  <DialogContextProvider slideFrom={slideFrom}>
    <RadixDialog.Root modal open={isOpen} onOpenChange={onClose}>
      <RadixDialog.DialogTitle className="hidden" />
      <RadixDialog.Portal>{children}</RadixDialog.Portal>
    </RadixDialog.Root>
  </DialogContextProvider>
);

Dialog.Overlay = DialogOverlay;
Dialog.Window = DialogWindow;
Dialog.Close = DialogClose;
