import React, { forwardRef } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';

export const PopoverTrigger = forwardRef<HTMLDivElement, RadixPopover.PopoverTriggerProps>(
  ({ children, ...props }, ref) => (
    <RadixPopover.Trigger {...props}>
      <div ref={ref}>{children}</div>
    </RadixPopover.Trigger>
  )
);
