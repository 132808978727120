import { FC, useState } from 'react';
import { Button, Avatar, Popover } from 'ui-v2';
import { cn, getInitials } from 'utils';

interface AuthMenuProps {
  isLoggedIn: boolean;
  userName: string;
  onSubmit: () => void;
  onSignIn: () => void;
  onSignOut: () => void;
}

export const AuthMenu: FC<AuthMenuProps> = ({
  isLoggedIn,
  userName,
  onSubmit,
  onSignIn,
  onSignOut,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <div className={cn('flex', isLoggedIn ? 'items-center gap-4' : 'gap-2.5')}>
      <Button variant="outline" color="midnight" onClick={onSubmit}>
        Submit Data
      </Button>

      {!isLoggedIn ? (
        <Button variant="outline" color="barbie" onClick={onSignIn}>
          My Account
        </Button>
      ) : (
        <Popover open={isMenuOpened} onOpenChange={setIsMenuOpened}>
          <Popover.Trigger>
            <Avatar initials={getInitials(userName)} />
          </Popover.Trigger>
          <Popover.Content>
            <div className="space-y-3">
              <p className="text-midnight text-center text-sm">
                Are you sure you'd like to sign out?
              </p>
              <div className="flex gap-3">
                <Button variant="outline" color="midnight" onClick={() => setIsMenuOpened(false)}>
                  Cancel
                </Button>
                <Button variant="outline" color="barbie" onClick={onSignOut}>
                  Sign Out
                </Button>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      )}
    </div>
  );
};
