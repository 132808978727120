import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'utils';

interface PageProps {
  className?: string;
}

export const Page: FC<PropsWithChildren & PageProps> = ({ children, className }) => (
  <main className={cn('grow bg-gray-50 p-6 lg:px-20 lg:py-9', className)}>{children}</main>
);
