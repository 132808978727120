type Environment = 'production' | 'staging' | 'development';

type ConfigMap = {
  production?: string;
  staging?: string;
  development?: string;
  $default?: string;
};

const env: Environment = import.meta.env.VITE_APP_ENV || 'development';

const envBasedValue = (potentialValues: ConfigMap) =>
  potentialValues[env] ?? potentialValues.$default;

const config = {
  env,

  apollo: {
    uri: envBasedValue({
      production: 'https://irisdata.thegiin.org/graphql',
      staging: 'https://irisvis.globobeet.org/api/graphql',
      $default: import.meta.env.VITE_GRAPHQL_URI || 'http://localhost:4000/graphql',
    }),
  },
};

export default config;
