import type { TOptions } from 'i18next';

import { useTranslation } from 'react-i18next';

export function useChartTranslations(prefix: string) {
  const { t } = useTranslation();

  const translate = (key: string, options?: TOptions): string | null => {
    const compiledKey = `${prefix}_${key}`;
    const result = t(compiledKey, options);
    return result === compiledKey ? null : result;
  };

  const getOptionLabel = (label: Maybe<string>): string => {
    const safeLabel = label || '';
    const labelKey = safeLabel.toLowerCase().replace(/[^a-z0-9]/g, '');
    return translate(`optionLabel_${labelKey}`) ?? safeLabel;
  };

  const getOptionTooltip = (label: Maybe<string>): string => {
    const optionLabel = getOptionLabel(label);
    return translate('optionTooltip', { label: optionLabel }) ?? optionLabel;
  };

  const getOptionDisplayValue = (
    value: Maybe<string | number>,
    context?: Record<string, Maybe<string | number>>
  ) => {
    return translate('displayValue', { value, ...context }) ?? String(value);
  };

  return {
    t: translate,
    title: translate('title'),
    titleTooltipKPI: translate('titleTooltip_kpi'),
    subtitle: translate('subtitle'),
    chartTooltip: translate('chartTooltip'),
    getOptionLabel,
    getOptionTooltip,
    getOptionDisplayValue,
  };
}
