import React, { PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

type BigStatProps = PropsWithChildren<{
  label?: string;
  unit?: string;
  className?: string;
}>;

export const BigStat: React.FC<BigStatProps> = ({ label, unit, children, className }) => (
  <div className={cn('flex flex-col', className)}>
    {label && <span className="text-[0.625rem] leading-3 text-gray-900 sm:text-xs">{label}</span>}
    <div className="flex items-start gap-1 sm:gap-2">
      <span className="text-midnight text-[1.375rem] leading-7 sm:text-4xl sm:leading-[3rem]">
        {children}
      </span>
      {unit && (
        <span className="text-midnight mt-1 inline-block self-start text-xs font-semibold leading-[0.875rem] sm:mt-3">
          {unit}
        </span>
      )}
    </div>
  </div>
);
