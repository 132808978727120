import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BigStat, ChartCard, InfoTooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';
import { faAum } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';

export const Index_AssetsUnderManagementFragment = gql(/* GraphQL */ `
  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {
    assetsUnderManagement {
      average
      median
      n
      isDataSuppressed
    }
  }
`);

type AssetsUnderManagementProps = {
  className?: string;
  indicators: FragmentType<typeof Index_AssetsUnderManagementFragment>;
};

export const AssetsUnderManagement: React.FC<AssetsUnderManagementProps> = ({
  indicators,
  className,
}) => {
  const { assetsUnderManagement } = useFragment(Index_AssetsUnderManagementFragment, indicators);

  const { title, chartTooltip } = useChartTranslations('assetsUnderManagement');

  const average = Math.floor((assetsUnderManagement.average ?? 0) / 1000000);
  const median = Math.floor((assetsUnderManagement.median ?? 0) / 1000000);

  return (
    <ChartCard className={className}>
      <ChartCard.Body
        isDataSuppressed={assetsUnderManagement.isDataSuppressed}
        className="space-y-1.5"
      >
        <div className="mb-3 flex items-center justify-between lg:mb-1.5">
          <BigStat className="font-light" label="Average" unit="MIL. USD">
            ${average}
          </BigStat>
          <BigStat className="font-light" label="Median" unit="MIL. USD">
            ${median}
          </BigStat>
          <FontAwesomeIcon
            icon={faAum}
            size="2xl"
            className="text-ocean size-6 self-start lg:size-8"
          />
        </div>
        <ChartCard.Title className="text-ocean">
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Body>
    </ChartCard>
  );
};
