import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'utils';

interface LinkProps {
  className?: string;
  href: string;
}

export const Link: FC<LinkProps & PropsWithChildren> = ({ className, href, children }) => (
  <a target="_blank" href={href} className={cn('text-barbie underline', className)}>
    {children}
  </a>
);
