import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

export enum VerticalBarDatasetKey {
  investment = 'investment',
  benchmark = 'benchmark',
}

export interface VerticalBarContextProps {
  groups: {
    key: string; // Matches key in data
    label: string; // Annual pace of income change (in %)
  }[];
  data: {
    key: VerticalBarDatasetKey; // investment || benchmark
    label: string; // Your investment
    isDashed?: boolean;
    color: string; // #FF0000
    groups: {
      [key: string]: {
        value: number | null | undefined;
        displayValue: string | null | undefined;
      };
    };
  }[];
}

interface IVerticalBarContext extends VerticalBarContextProps {
  isAllDataMissing: boolean;
}

const VerticalBarContext = createContext<IVerticalBarContext | null>(null);

export const VerticalBarContextProvider: FC<PropsWithChildren & VerticalBarContextProps> = ({
  groups,
  data,
  children,
}) => {
  const isAllDataMissing = useMemo(
    () =>
      data
        .map((item) => Object.values(item.groups).map((group) => group.value))
        .flat()
        .every((value) => value === null || value === undefined),
    [data]
  );

  const contextValue = useMemo(
    () => ({ groups, data, isAllDataMissing }),
    [groups, data, isAllDataMissing]
  );

  return <VerticalBarContext.Provider value={contextValue}>{children}</VerticalBarContext.Provider>;
};

export function useVerticalBarContext() {
  const ctx = useContext(VerticalBarContext);

  if (!ctx) throw new Error('VerticalBar.* components must be rendered within the VerticalBar');

  return ctx;
}
