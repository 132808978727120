import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { cn } from 'utils/cn';

const swatchStyles = cva('inline-block rounded-sm', {
  variants: {
    size: {
      default: 'min-w-3.5 min-h-3.5',
      small: 'min-w-2.5 min-h-2.5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export interface SwatchProps extends VariantProps<typeof swatchStyles> {
  color: string;
  className?: string;
}

export const Swatch: React.FC<SwatchProps> = ({ color, size, className }) => (
  <span className={cn(swatchStyles({ size }), className)} style={{ backgroundColor: color }} />
);
