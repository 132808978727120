import { type FragmentType, gql, useFragment } from '@/generated';
import { PercentOfTotalItem } from '@/generated/graphql';
import { useChartTranslations } from '@/hooks/useChartTranslations';
import {
  faSdg1,
  faSdg10,
  faSdg11,
  faSdg12,
  faSdg13,
  faSdg14,
  faSdg15,
  faSdg16,
  faSdg17,
  faSdg2,
  faSdg3,
  faSdg4,
  faSdg5,
  faSdg6,
  faSdg7,
  faSdg8,
  faSdg9,
} from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { ChartCard, HorizontalBars, InfoTooltip } from 'ui-v2';
import { getSdgColors } from 'ui-v2/theme';

const sdgColors = getSdgColors();

const sdgIcons: Record<number, ReactNode> = {
  1: <FontAwesomeIcon icon={faSdg1} size="2x" className="text-sdg-1" />,
  2: <FontAwesomeIcon icon={faSdg2} size="2x" className="text-sdg-2" />,
  3: <FontAwesomeIcon icon={faSdg3} size="2x" className="text-sdg-3" />,
  4: <FontAwesomeIcon icon={faSdg4} size="2x" className="text-sdg-4" />,
  5: <FontAwesomeIcon icon={faSdg5} size="2x" className="text-sdg-5" />,
  6: <FontAwesomeIcon icon={faSdg6} size="2x" className="text-sdg-6" />,
  7: <FontAwesomeIcon icon={faSdg7} size="2x" className="text-sdg-7" />,
  8: <FontAwesomeIcon icon={faSdg8} size="2x" className="text-sdg-8" />,
  9: <FontAwesomeIcon icon={faSdg9} size="2x" className="text-sdg-9" />,
  10: <FontAwesomeIcon icon={faSdg10} size="2x" className="text-sdg-10" />,
  11: <FontAwesomeIcon icon={faSdg11} size="2x" className="text-sdg-11" />,
  12: <FontAwesomeIcon icon={faSdg12} size="2x" className="text-sdg-12" />,
  13: <FontAwesomeIcon icon={faSdg13} size="2x" className="text-sdg-13" />,
  14: <FontAwesomeIcon icon={faSdg14} size="2x" className="text-sdg-14" />,
  15: <FontAwesomeIcon icon={faSdg15} size="2x" className="text-sdg-15" />,
  16: <FontAwesomeIcon icon={faSdg16} size="2x" className="text-sdg-16" />,
  17: <FontAwesomeIcon icon={faSdg17} size="2x" className="text-sdg-17" />,
};

export const Index_StrategicDevelopmentGoalsFragment = gql(/* GraphQL */ `
  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {
    sdgs {
      items {
        count
        label
        pct
      }
      isDataSuppressed
    }
  }
`);

type StrategicDevelopmentGoalsProps = {
  className?: string;
  indicators: FragmentType<typeof Index_StrategicDevelopmentGoalsFragment>;
};

interface Datum extends PercentOfTotalItem {
  label: string;
  icon: ReactNode;
  color: string;
}

export function StrategicDevelopmentGoals({
  indicators,
  className,
}: StrategicDevelopmentGoalsProps) {
  const { sdgs } = useFragment(Index_StrategicDevelopmentGoalsFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip } =
    useChartTranslations('sdgsTargeted');

  const filteredData = sdgs.items.filter(
    (item: PercentOfTotalItem | null): item is PercentOfTotalItem & { label: string } => {
      return Boolean(item !== null && item.label);
    }
  );

  const sortedData = Object.entries(sdgIcons).reduce<Datum[]>((acc, [key, value]) => {
    acc.push({
      // Empty values
      count: 0,
      pct: 0,

      // Real values
      ...filteredData.find((x) => x.label.startsWith(`${key}.`)),

      // Override values
      label: getOptionLabel(key),
      color: sdgColors[`sdg-${key}`],
      icon: value,
    });

    return acc;
  }, []);

  const min = Math.min(0, ...sortedData.map((item) => item.count));
  const max = Math.max(...sortedData.map((item) => item.count));

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body isDataSuppressed={sdgs.isDataSuppressed}>
        <HorizontalBars min={min} max={max} showInlineDisplayValue={false}>
          {sortedData.map((item, i) => (
            <HorizontalBars.Item
              key={i}
              label={item.label}
              tooltipLabel={getOptionTooltip(item.label.trim())}
              value={item.count}
              displayValue={item.count.toLocaleString()}
              color={item.color}
              icon={item.icon}
            />
          ))}
        </HorizontalBars>
      </ChartCard.Body>
    </ChartCard>
  );
}
