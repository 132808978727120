import React, { FC, ReactNode } from 'react';
import { decode } from 'html-entities';
import { HelperText } from '../HelperText';
import { Swatch } from '../Swatch';

type TooltipChartValueProps = {
  label: string;
  value: ReactNode;
  color?: string;
};

export const TooltipChartValue: FC<TooltipChartValueProps> = ({ label, value, color }) => (
  <div className="flex items-start justify-between gap-4">
    <div className="flex items-start gap-1">
      {color && <Swatch color={color} />}
      <HelperText className="text-midnight">{decode(label)}</HelperText>
    </div>
    <HelperText className="whitespace-nowrap text-gray-900">{value}</HelperText>
  </div>
);
