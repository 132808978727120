/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query GetFilterCounts {\n    forestryIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      impactStrategy {\n        items {\n          label\n          count\n        }\n      }\n      forestType {\n        items {\n          label\n          count\n        }\n      }\n      ecoRegion {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n": types.GetFilterCountsDocument,
    "\n  query GetReportingPeriod {\n    healthcareIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n": types.GetReportingPeriodDocument,
    "\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n": types.ValidateFiltersDocument,
    "\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n": types.GetViewerDocument,
    "\n  fragment Index_AssetClassFragment on HealthcareIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_AssetClassFragmentFragmentDoc,
    "\n  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n": types.Index_AssetsUnderManagementFragmentFragmentDoc,
    "\n  fragment Index_CertificationsPresentFragment on HealthcareIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_CertificationsPresentFragmentFragmentDoc,
    "\n  fragment Index_CountryIncomeGroupFragment on ForestryIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_CountryIncomeGroupFragmentFragmentDoc,
    "\n  fragment Index_FinancialReturnsFragment on ForestryIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_FinancialReturnsFragmentFragmentDoc,
    "\n  fragment Index_GeographicFocusFragment on ForestryIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_GeographicFocusFragmentFragmentDoc,
    "\n  fragment Index_HealthcareTypeFragment on HealthcareIndicators {\n    healthcareType {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_HealthcareTypeFragmentFragmentDoc,
    "\n  fragment Index_InvestmentsRepresentedFragment on HealthcareIndicators {\n    numberOfInvestees\n  }\n": types.Index_InvestmentsRepresentedFragmentFragmentDoc,
    "\n  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {\n    numberOfOrgs\n  }\n": types.Index_InvestorsRepresentedFragmentFragmentDoc,
    "\n  fragment Index_PresenceOfCertificationsFragment on HealthcareIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_PresenceOfCertificationsFragmentFragmentDoc,
    "\n  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n": types.Index_RegionsAndCountriesFragmentFragmentDoc,
    "\n  fragment Index_SettingOfCareFragment on HealthcareIndicators {\n    settingOfCare {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_SettingOfCareFragmentFragmentDoc,
    "\n  fragment Index_StageOfBusinessFragment on HealthcareIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StageOfBusinessFragmentFragmentDoc,
    "\n  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {\n    sdgs {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StrategicDevelopmentGoalsFragmentFragmentDoc,
    "\n  fragment Index_StrategicGoalsFragment on ForestryIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n": types.Index_StrategicGoalsFragmentFragmentDoc,
    "\n  query IndexPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Index_InvestorsRepresentedFragment\n      ...Index_InvestmentsRepresentedFragment\n      ...Index_AssetsUnderManagementFragment\n      # ...Index_FinancialReturnsFragment\n      ...Index_AssetClassFragment\n      # ...Index_CountryIncomeGroupFragment\n      ...Index_RegionsAndCountriesFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_HealthcareTypeFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_SettingOfCareFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_CertificationsPresentFragment\n    }\n  }\n": types.IndexPageDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFilterCounts {\n    forestryIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      impactStrategy {\n        items {\n          label\n          count\n        }\n      }\n      forestType {\n        items {\n          label\n          count\n        }\n      }\n      ecoRegion {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetFilterCounts {\n    forestryIndicators {\n      assetClass {\n        items {\n          count\n          label\n        }\n      }\n      yearsSinceInvestment {\n        items {\n          count\n          label\n        }\n      }\n      stageOfBusiness {\n        items {\n          label\n          count\n        }\n      }\n      incomeGroup {\n        items {\n          label\n          count\n        }\n      }\n      primaryCountryByRegion {\n        items {\n          label\n          count\n        }\n      }\n      impactStrategy {\n        items {\n          label\n          count\n        }\n      }\n      forestType {\n        items {\n          label\n          count\n        }\n      }\n      ecoRegion {\n        items {\n          label\n          count\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetReportingPeriod {\n    healthcareIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetReportingPeriod {\n    healthcareIndicators {\n      reportingPeriod {\n        min\n        max\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n"): (typeof documents)["\n  query ValidateFilters($filter: [FilterCond]) {\n    forestryIndicators(filter: $filter) {\n      numberOfOrgs\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n"): (typeof documents)["\n  query GetViewer {\n    me {\n      irisVersion\n      orgId\n      salesforceAccountId\n      userId\n      userName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_AssetClassFragment on HealthcareIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_AssetClassFragment on HealthcareIndicators {\n    assetClass {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_AssetsUnderManagementFragment on HealthcareIndicators {\n    assetsUnderManagement {\n      average\n      median\n      n\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_CertificationsPresentFragment on HealthcareIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_CertificationsPresentFragment on HealthcareIndicators {\n    certifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_CountryIncomeGroupFragment on ForestryIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_CountryIncomeGroupFragment on ForestryIndicators {\n    incomeGroup {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_FinancialReturnsFragment on ForestryIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_FinancialReturnsFragment on ForestryIndicators {\n    targetFinancialReturns {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_GeographicFocusFragment on ForestryIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_GeographicFocusFragment on ForestryIndicators {\n    geographicFocus {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_HealthcareTypeFragment on HealthcareIndicators {\n    healthcareType {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_HealthcareTypeFragment on HealthcareIndicators {\n    healthcareType {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_InvestmentsRepresentedFragment on HealthcareIndicators {\n    numberOfInvestees\n  }\n"): (typeof documents)["\n  fragment Index_InvestmentsRepresentedFragment on HealthcareIndicators {\n    numberOfInvestees\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {\n    numberOfOrgs\n  }\n"): (typeof documents)["\n  fragment Index_InvestorsRepresentedFragment on HealthcareIndicators {\n    numberOfOrgs\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_PresenceOfCertificationsFragment on HealthcareIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_PresenceOfCertificationsFragment on HealthcareIndicators {\n    hasCertifications {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {\n    primaryCountryByRegion {\n      items {\n        count\n        label\n        pct\n        items {\n          count\n          label\n          pct\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_SettingOfCareFragment on HealthcareIndicators {\n    settingOfCare {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_SettingOfCareFragment on HealthcareIndicators {\n    settingOfCare {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StageOfBusinessFragment on HealthcareIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StageOfBusinessFragment on HealthcareIndicators {\n    stageOfBusiness {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {\n    sdgs {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StrategicDevelopmentGoalsFragment on HealthcareIndicators {\n    sdgs {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Index_StrategicGoalsFragment on ForestryIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"): (typeof documents)["\n  fragment Index_StrategicGoalsFragment on ForestryIndicators {\n    strategicGoals {\n      items {\n        count\n        label\n        pct\n      }\n      isDataSuppressed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query IndexPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Index_InvestorsRepresentedFragment\n      ...Index_InvestmentsRepresentedFragment\n      ...Index_AssetsUnderManagementFragment\n      # ...Index_FinancialReturnsFragment\n      ...Index_AssetClassFragment\n      # ...Index_CountryIncomeGroupFragment\n      ...Index_RegionsAndCountriesFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_HealthcareTypeFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_SettingOfCareFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_CertificationsPresentFragment\n    }\n  }\n"): (typeof documents)["\n  query IndexPage($filter: [FilterCond]) {\n    healthcareIndicators(filter: $filter) {\n      ...Index_InvestorsRepresentedFragment\n      ...Index_InvestmentsRepresentedFragment\n      ...Index_AssetsUnderManagementFragment\n      # ...Index_FinancialReturnsFragment\n      ...Index_AssetClassFragment\n      # ...Index_CountryIncomeGroupFragment\n      ...Index_RegionsAndCountriesFragment\n      ...Index_StrategicDevelopmentGoalsFragment\n      ...Index_HealthcareTypeFragment\n      ...Index_StageOfBusinessFragment\n      ...Index_SettingOfCareFragment\n      ...Index_PresenceOfCertificationsFragment\n      ...Index_CertificationsPresentFragment\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;