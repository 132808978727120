import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { BenchmarkConfig } from 'types/global';

export const CMSContext = createContext<BenchmarkConfig | undefined>(undefined);

export const CMSContextProvider: FC<PropsWithChildren> = ({ children }) => {
  return <CMSContext.Provider value={window.__benchmarkConfig}>{children}</CMSContext.Provider>;
};

export function useCMSContext() {
  const ctx = useContext(CMSContext);

  if (!ctx) throw new Error('Component must be used within CMSContextProvider');

  return ctx;
}
