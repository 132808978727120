import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { cn } from 'utils/cn';

const avatarStyles = cva(
  'flex items-center justify-center rounded-full bg-gray-50 border-0.5 border-gray-300 overflow-hidden',
  {
    variants: {
      size: {
        default: 'size-9',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

export interface AvatarProps extends VariantProps<typeof avatarStyles> {
  src?: string;
  alt?: string;
  initials?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ src, alt, initials, size, className }) => (
  <div className={cn(avatarStyles({ size }), className)}>
    {src ? (
      <img src={src} alt={alt} className="h-full w-full object-cover" />
    ) : (
      <span className="text-xs text-gray-900">{initials}</span>
    )}
  </div>
);
