import React, { type ComponentProps } from 'react';
import { cn } from 'utils';

import { HelperText } from '../HelperText';

export function ChartCardSubtitle({
  className,
  children,
  ...props
}: ComponentProps<typeof HelperText>) {
  return (
    <HelperText className={cn('uppercase', className)} {...props}>
      {children}
    </HelperText>
  );
}
