import React from 'react';

export interface DateRangeProps {
  min: number;
  max: number;
}

export const DateRange: React.FC<DateRangeProps> = ({ min, max }) => {
  const textStyles = 'text-midnight text-xs font-light';

  return (
    <div
      className={
        'flex min-h-11 min-w-11 flex-col items-center justify-center gap-0.5 rounded bg-blue-50 px-1 py-0.5'
      }
    >
      <span className={textStyles}>{min}</span>
      <div className={'bg-midnight h-px w-3.5'} />
      <span className={textStyles}>{max}</span>
    </div>
  );
};
