import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { FilterGroupProps } from './types';

export const FilterGroupContext = createContext<FilterGroupProps | undefined>(undefined);

export function useFilterGroupContext() {
  const ctx = useContext(FilterGroupContext);

  if (!ctx) throw new Error('FilterGroup.* components must be rendered within a FilterGroup');

  return ctx;
}

export const FilterGroupContextProvider: FC<PropsWithChildren & FilterGroupProps> = ({
  children,
  filters,
  toggleFilter,
}) => {
  const contextValue = useMemo(() => ({ filters, toggleFilter }), [filters, toggleFilter]);

  return <FilterGroupContext.Provider value={contextValue}>{children}</FilterGroupContext.Provider>;
};
