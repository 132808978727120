import React from 'react';
import { LinkProps, Link as RouterLink } from '@tanstack/react-router';
import { cva, VariantProps } from 'class-variance-authority';
import { useNavMenuContext } from './NavMenuContext';

const style = {
  link: cva(
    'text-gray-400 transition-colors duration-200 hover:text-midnight relative flex flex-col justify-around',
    {
      variants: {
        layout: {
          mobile: 'text-right text-sm font-normal',
          desktop: 'text-base',
        },
        isActive: {
          true: 'text-midnight',
          false: '',
        },
      },
      defaultVariants: {
        layout: 'desktop',
      },
    }
  ),

  underline: cva('bg-barbie mt-1 inline-block h-[2px] w-full', {
    variants: {
      layout: {
        desktop: 'absolute -bottom-2',
        mobile: '',
      },
    },
  }),
};

export type NavLinkProps = VariantProps<typeof style.link> &
  LinkProps & {
    onClick?: () => void;
  };

export const Link: React.FC<NavLinkProps> = ({ children, to, onClick }) => {
  const { layout, location, activeLink } = useNavMenuContext();
  const isActive = to === (activeLink || location);

  return (
    <li className={style.link({ layout, isActive })} onClick={onClick}>
      <RouterLink to={to}>{children}</RouterLink>
      {isActive && <div className={style.underline({ layout })} />}
    </li>
  );
};
