import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders, faClose } from '@fa-icons/classic/solid';

import { Button } from '../Button/index';

type ActionProps = {
  isActive: boolean;
};

export const Action: React.FC<ActionProps> = ({ isActive }) => (
  <div className="flex items-center space-x-2.5">
    <Button
      variant="borderless"
      className="h-4 min-w-fit p-0"
      icon={
        <FontAwesomeIcon
          className="text-barbie size-4 -rotate-90"
          icon={isActive ? faClose : faSliders}
        />
      }
    >
      <span className="text-nowrap text-xs">Add Filters</span>
    </Button>

    <div className="bg-barbie h-3 w-px" />
  </div>
);
