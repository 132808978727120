import { isNumber, times } from 'lodash-es';
import numeral from 'numeral';

type NumberFormatValue = number | null;
type DecimalDefinition = number | 'auto' | null;

const getDecimalFormat = (decimals: DecimalDefinition = 'auto') => {
  if (decimals === 'auto') return '.[00]';
  if (isNumber(decimals)) return `.${times(decimals, () => '0').join('')}`;
  return '';
};

type FormatNumberOptions = {
  decimals?: DecimalDefinition;
};

export const formatNumber = (
  x?: NumberFormatValue,
  { decimals = null }: FormatNumberOptions = {}
): string => {
  if (x == null) return '0';

  // User-provided specific decimal places
  if (decimals != null) return numeral(x).format(`0,0${getDecimalFormat(decimals)}`);

  // Provide 2 decimal places for 0-based values
  const isZeroBased = x < 1 && x > -1;
  return numeral(x).format(`0,0${isZeroBased ? getDecimalFormat('auto') : ''}`);
};

type FormatPercentageOptions = {
  isDecimal?: boolean;
  decimals?: DecimalDefinition;
  omitDecimalAt100?: boolean;
};

export const formatPercentage = (
  x?: NumberFormatValue,
  { isDecimal, decimals = 1, omitDecimalAt100 = false }: FormatPercentageOptions = {}
): string => {
  let value = x || 0;
  if (isDecimal) value *= 100;
  if (omitDecimalAt100 && value === 100) return '100%';
  return `${numeral(value).format(`0,0${getDecimalFormat(decimals)}`)}%`;
};

type FormatCurrencyOptions = {
  cents?: boolean;
};

export const formatCurrency = (
  x?: NumberFormatValue,
  { cents = false }: FormatCurrencyOptions = {}
): string => numeral(x).format(`$0,0${cents ? '.00' : ''}`);

export const parseNumber = (x?: string): number | null => {
  if (!x) return null;
  return numeral(x).value();
};

export const getInitials = (fullName: string) => {
  const nameArray = fullName.trim().split(' ');
  const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
  const lastNameInitial =
    nameArray.length === 1 ? '' : nameArray[nameArray.length - 1].charAt(0).toUpperCase();
  return firstNameInitial + lastNameInitial;
};

export const isNaValue = (value: number | string | null | undefined) => {
  return value === null || value === undefined;
};
