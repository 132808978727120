import React from 'react';
import { cn } from 'utils/cn';

interface PageTitleProps {
  subheading?: string;
  heading: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ subheading, heading }) => (
  <hgroup className="space-y-0.5">
    <h1 className={cn('text-midnight text-xl leading-6 sm:text-3xl sm:leading-10')}>{heading}</h1>
    {subheading && (
      <h3 className={cn('text-sm font-light leading-5 text-gray-900 sm:text-lg sm:leading-6')}>
        {subheading}
      </h3>
    )}
  </hgroup>
);
