import { createContext, useContext } from 'react';

export interface NavMenuContextProps {
  layout: 'desktop' | 'mobile';
  location: string;
  activeLink?: string;
}

export const NavMenuContext = createContext<NavMenuContextProps | undefined>(undefined);

export function useNavMenuContext() {
  const ctx = useContext(NavMenuContext);

  if (!ctx) throw new Error('NavMenu.* components must be rendered within a NavMenu');

  return ctx;
}
