import React, { type ComponentProps } from 'react';
import { cn } from 'utils';

export type ChartCardBodyProps = ComponentProps<'section'> & {
  isDataSuppressed?: boolean;
  suppressedLabel?: string;
};

export function ChartCardBody({
  isDataSuppressed,
  suppressedLabel = 'Insufficient data, check back later',
  className,
  children,
  ...props
}: ChartCardBodyProps) {
  return (
    <section className={cn('flex flex-1 flex-col justify-center', className)} {...props}>
      {isDataSuppressed ? <p className="text-center text-gray-500">{suppressedLabel}</p> : children}
    </section>
  );
}
