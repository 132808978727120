import { useQuery } from '@apollo/client';

import { gql } from '@/generated';
import { useMemo } from 'react';

const GET_VIEWER = gql(/* GraphQL */ `
  query GetViewer {
    me {
      irisVersion
      orgId
      salesforceAccountId
      userId
      userName
    }
  }
`);

export const useViewer = () => {
  const query = useQuery(GET_VIEWER);

  return {
    ...query,
    viewer: useMemo(() => query.data?.me, [query.data]),
  };
};
