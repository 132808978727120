import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

interface LabelTextProps {
  className?: string;
}

export const LabelText: FC<PropsWithChildren & LabelTextProps> = ({ children, className }) => (
  <span
    className={cn(
      'inline-block text-[0.625rem] font-semibold uppercase leading-[0.875rem] text-gray-900 sm:text-[0.75rem] sm:leading-[0.875rem]',
      className
    )}
  >
    {children}
  </span>
);
