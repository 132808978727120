import { useLocation } from '@tanstack/react-router';
import React, { ReactNode, useMemo } from 'react';
import { Link } from './Link';
import { Links } from './Links';
import { NavMenuContext, NavMenuContextProps } from './NavMenuContext';
import { Separator } from './Separator';

interface NavMenuComposition {
  Links: typeof Links;
  Link: typeof Link;
  Separator: typeof Separator;
}

interface NavMenuProps extends Omit<NavMenuContextProps, 'location'> {
  children: ReactNode;
}

export const NavMenu: React.FC<NavMenuProps> & NavMenuComposition = ({
  layout,
  children,
  activeLink,
}) => {
  const location = useLocation().pathname;

  const contextValue = useMemo(
    () => ({ layout, location, activeLink }),
    [layout, location, activeLink]
  );

  return (
    <NavMenuContext.Provider value={contextValue}>
      <nav>{children}</nav>
    </NavMenuContext.Provider>
  );
};

NavMenu.Links = Links;
NavMenu.Link = Link;
NavMenu.Separator = Separator;
