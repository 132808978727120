import React from 'react';
import { cva } from 'class-variance-authority';
import * as RadixDialog from '@radix-ui/react-dialog';
import { cn } from 'utils';
import { useDialogContext } from './DialogContext';

const windowStyle = cva('fixed inset-0 z-20 rounded-md bg-white bottom-2 top-2 overflow-auto', {
  variants: {
    slideFrom: {
      left: 'animate-slideInLeft left-2 right-[10%] sm:right-1/3',
      right: 'animate-slideInRight right-2 left-[10%] sm:left-1/3',
    },
  },
});

export const DialogWindow = React.forwardRef<HTMLDivElement, RadixDialog.DialogContentProps>(
  ({ children, className }, ref) => {
    const { slideFrom } = useDialogContext();

    return (
      <RadixDialog.Content
        ref={ref}
        aria-describedby={undefined}
        className={cn(windowStyle({ slideFrom }), className)}
      >
        {children}
      </RadixDialog.Content>
    );
  }
);
