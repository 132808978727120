import React from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import { PopoverContent } from './Content';
import { PopoverTrigger } from './Trigger';

export interface PopoverComposition {
  Trigger: typeof PopoverTrigger;
  Content: typeof PopoverContent;
}

export const Popover: React.FC<RadixPopover.PopoverProps> & PopoverComposition = (props) => (
  <RadixPopover.Root {...props} />
);

Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
