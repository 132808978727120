import React, { ReactNode } from 'react';

import { HorizontalBar, type HorizontalBarProps } from '../HorizontalBar';
import { useHorizontalBarsContext } from './HorizontalBarsContext';

type HorizontalBarsItemProps = Omit<
  HorizontalBarProps,
  'max' | 'min' | 'showInlineDisplayValue'
> & {
  max?: number;
  min?: number;
  showInlineDisplayValue?: boolean;
  children?: ReactNode;
};

export function HorizontalBarsItem(props: HorizontalBarsItemProps) {
  const sharedProps = useHorizontalBarsContext();
  return <HorizontalBar {...sharedProps} {...props} />;
}
