import * as RadixDialog from '@radix-ui/react-dialog';
import React from 'react';

export interface DialogOverlayProps {
  onClose: () => void;
}

export const DialogOverlay = React.forwardRef<HTMLDivElement, DialogOverlayProps>(
  ({ onClose }, ref) => {
    return (
      <RadixDialog.Overlay
        ref={ref}
        aria-hidden
        className="bg-midnight fixed inset-0 z-10 opacity-30"
        onClick={onClose}
      />
    );
  }
);
