import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fa-icons/classic/solid';
import { Button } from '../Button';

interface FilterProps {
  label: string | number;
  onRemove: () => void;
}

export const Filter: React.FC<FilterProps> = ({ label, onRemove }) => (
  <div className="bg-coral-50 text-midnight flex h-4 items-center gap-2.5 rounded pl-1 pr-px text-xs">
    {label}

    <Button
      variant="borderless"
      className="bg-barbie size-3.5 min-w-fit rounded p-0 text-white"
      icon={<FontAwesomeIcon className="size-2.5" icon={faClose} />}
      onClick={onRemove}
    />
  </div>
);
