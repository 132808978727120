import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'utils';

interface HeadingProps {
  className?: string;
}

export const Heading: FC<HeadingProps & PropsWithChildren> = ({ className, children }) => (
  <h4 className={cn('text-midnight pb-1 text-[1.375rem] font-light leading-7', className)}>
    {children}
  </h4>
);
