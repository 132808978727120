import React, { FC } from 'react';
import { Cell, Pie, PieChart as RePieChart, Tooltip as ReTooltip } from 'recharts';
import { cn } from 'utils';

import { ChartLegend } from '../ChartLegend';
import { PieTooltip } from './PieTooltip';

export interface PieChartData {
  label: string;
  tooltipLabel?: string;
  value: number;
  color: string;
  displayValue?: string;
}

export interface PieChartProps {
  data: PieChartData[];
  chartPosition?: 'left' | 'right';
  showLegend?: boolean;
}

export const PieChart: FC<PieChartProps> = ({ data, chartPosition = 'left', showLegend }) => {
  const isSingleValue = data.length === 1;

  return (
    <div
      className={cn('flex items-center justify-between gap-8', {
        'flex-row': chartPosition === 'left',
        'flex-row-reverse': chartPosition === 'right',
      })}
    >
      <RePieChart width={155} height={155}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="label"
          paddingAngle={isSingleValue ? 0 : 2}
          innerRadius={40}
          outerRadius={70}
          startAngle={isSingleValue ? 0 : 90}
          endAngle={isSingleValue ? 360 : -270}
          cornerRadius={2.5}
        >
          {data.map((entry: PieChartData) => (
            <Cell
              key={entry.label}
              fill={entry.color}
              stroke="transparent"
              className="outline-none"
            />
          ))}
        </Pie>

        <ReTooltip
          content={<PieTooltip />}
          allowEscapeViewBox={{ y: true, x: true }}
          isAnimationActive={false}
        />
      </RePieChart>

      {showLegend && <ChartLegend data={data} className="flex-1" />}
    </div>
  );
};
