import React from 'react';

interface TooltipContextValue {
  variant: 'static' | 'tooltip' | 'popover';
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export const TooltipContext = React.createContext<TooltipContextValue | undefined>(undefined);

export function useTooltipContext() {
  const context = React.useContext(TooltipContext);
  if (!context) {
    throw new Error('useTooltipContext must be used within a TooltipProvider');
  }
  return context;
}
