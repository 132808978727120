import { countryToAlpha2 } from 'country-to-iso';
import { getName } from 'i18n-iso-countries';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { ChartCard, generateChartColors, HorizontalBars, InfoTooltip, Tooltip } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { useChartTranslations } from '@/hooks/useChartTranslations';

export const Index_RegionsAndCountriesFragment = gql(/* GraphQL */ `
  fragment Index_RegionsAndCountriesFragment on HealthcareIndicators {
    primaryCountryByRegion {
      items {
        count
        label
        pct
        items {
          count
          label
          pct
        }
      }
    }
  }
`);

type RegionsAndCountriesProps = {
  className?: string;
  indicators: FragmentType<typeof Index_RegionsAndCountriesFragment>;
};

export function RegionsAndCountries({ indicators, className }: RegionsAndCountriesProps) {
  const { primaryCountryByRegion } = useFragment(Index_RegionsAndCountriesFragment, indicators);

  const { title, subtitle, chartTooltip, getOptionLabel, getOptionTooltip } =
    useChartTranslations('region');

  const colors = useMemo(
    () => generateChartColors(primaryCountryByRegion.items.length),
    [primaryCountryByRegion.items.length]
  );

  const formattedChartData = useMemo(() => {
    const dataWithSortedCountries = primaryCountryByRegion.items
      .filter((item) => !!item)
      .map((item) => {
        const sortedCountries = item.items.length ? orderBy(item.items, 'count', 'desc') : [];
        const mappedCountries = sortedCountries.map((item) => ({
          ...item,
          label: item?.label ? countryToAlpha2(item.label)?.toLowerCase() : '',
        }));
        return {
          ...item,
          items: mappedCountries,
        };
      });

    return dataWithSortedCountries;
  }, [primaryCountryByRegion.items]);

  const sortedItems = useMemo(
    () => orderBy(formattedChartData, ['pct'], ['desc']),
    [formattedChartData]
  );

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <ChartCard.Title>
          {title}
          {chartTooltip && <InfoTooltip className="ml-1">{chartTooltip}</InfoTooltip>}
        </ChartCard.Title>
        {subtitle && <ChartCard.Subtitle>{subtitle}</ChartCard.Subtitle>}
      </ChartCard.Header>
      <ChartCard.Body>
        <HorizontalBars min={0} max={100} showInlineDisplayValue={false}>
          {sortedItems.map((item, i) => {
            if (!item) return null;
            const hiddenCountriesCount = item.items.length > 3 ? item.items.length - 3 : '';
            const countriesToShow = item.items.slice(0, 3).reverse();
            return (
              <HorizontalBars.Item
                key={i}
                label={getOptionLabel(item.label)}
                tooltipLabel={getOptionTooltip(item.label)}
                value={item.pct}
                displayValue={item.count.toLocaleString()}
                color={colors[i]}
              >
                <div className="flex items-center gap-3">
                  {hiddenCountriesCount && (
                    <span className="text-barbie order-2 inline-block lg:order-1">
                      +{hiddenCountriesCount}
                    </span>
                  )}
                  <div className="order-1 flex gap-1 lg:order-2">
                    {countriesToShow.map((item, i) => {
                      if (!item.label) return null;
                      return (
                        <Tooltip variant="tooltip" key={i}>
                          <Tooltip.Trigger>
                            <div>
                              <CircleFlag
                                countryCode={item?.label || ''}
                                className="size-4 lg:size-6"
                              />
                            </div>
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            <Tooltip.ChartValue
                              label={getName(item.label, 'en') || ''}
                              value={item.count}
                            />
                          </Tooltip.Content>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              </HorizontalBars.Item>
            );
          })}
        </HorizontalBars>
      </ChartCard.Body>
    </ChartCard>
  );
}
