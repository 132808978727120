import { faCheck } from '@fa-icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cva } from 'class-variance-authority';
import * as React from 'react';
import { useId } from 'react';

export interface CheckboxProps {
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  children?: React.ReactNode;
}

const style = {
  container: cva('flex items-center gap-2', {
    variants: {
      labelPosition: {
        left: 'flex-row-reverse',
        right: '',
      },
    },
  }),
  checkbox: cva('flex items-center justify-center rounded border', {
    variants: {
      checked: {
        yes: 'bg-barbie border-barbie text-white',
        no: 'border-midnight bg-transparent',
      },
      hasLabel: {
        yes: 'size-4',
        no: 'size-5',
      },
      disabled: {
        yes: 'cursor-not-allowed opacity-20',
        no: '',
      },
    },
  }),
  icon: cva('text-white', {
    variants: {
      hasLabel: {
        yes: 'size-3',
        no: 'size-4',
      },
    },
  }),
  label: cva('text-sm font-normal', {
    variants: {
      disabled: {
        yes: 'text-gray-400 cursor-not-allowed',
        no: 'text-gray-900 cursor-pointer',
      },
    },
  }),
};

export const Checkbox: React.FC<CheckboxProps> = ({
  labelPosition = 'right',
  disabled = false,
  checked,
  onCheckedChange,
  children,
}) => {
  const checkboxId = useId();
  const hasLabel = !!children;

  return (
    <div className={style.container({ labelPosition })}>
      <CheckboxPrimitive.Root
        id={checkboxId}
        className={style.checkbox({
          disabled: disabled ? 'yes' : 'no',
          hasLabel: hasLabel ? 'yes' : 'no',
          checked: checked ? 'yes' : 'no',
        })}
        disabled={disabled}
        checked={checked}
        onCheckedChange={onCheckedChange}
      >
        <FontAwesomeIcon
          className={style.icon({ hasLabel: hasLabel ? 'yes' : 'no' })}
          icon={faCheck}
        />
      </CheckboxPrimitive.Root>
      {children && (
        <label className={style.label({ disabled: disabled ? 'yes' : 'no' })} htmlFor={checkboxId}>
          {children}
        </label>
      )}
    </div>
  );
};
