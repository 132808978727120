import React, { type ComponentProps } from 'react';
import { cn } from 'utils';

import { HorizontalBarsItem } from './HorizontalBarsItem';
import { HorizontalBarsContext, type HorizontalBarsContextValue } from './HorizontalBarsContext';

type HorizontalBarsProps = ComponentProps<'div'> & HorizontalBarsContextValue;

export function HorizontalBars({
  min,
  max,
  showInlineDisplayValue,
  className,
  children,
  ...props
}: HorizontalBarsProps) {
  return (
    <HorizontalBarsContext.Provider value={{ min, max, showInlineDisplayValue }}>
      <div className={cn('space-y-4', className)} {...props}>
        {children}
      </div>
    </HorizontalBarsContext.Provider>
  );
}

HorizontalBars.Item = HorizontalBarsItem;
