import React, { FC, PropsWithChildren } from 'react';
import { Filter } from './Filter';
import { FilterGroupContextProvider } from './FilterGroupContext';
import { Filters } from './Filters';
import { Title } from './Title';
import { FilterGroupProps } from './types';

export interface FilterGroupComposition {
  Title: typeof Title;
  Filters: typeof Filters;
  Filter: typeof Filter;
}

export const FilterGroup: FC<PropsWithChildren & FilterGroupProps> & FilterGroupComposition = ({
  children,
  filters,
  toggleFilter,
}) => (
  <FilterGroupContextProvider filters={filters} toggleFilter={toggleFilter}>
    <div>{children}</div>
  </FilterGroupContextProvider>
);

FilterGroup.Title = Title;
FilterGroup.Filters = Filters;
FilterGroup.Filter = Filter;
